<template>
  <div>
    <div id="navbar">
      <div class="side">
        <div
          v-show="!operatorMode"
          class="toggle sidebar-open-button"
          @click="toggleFullScreenMenu"
        >
          <HamburgerIcon
            v-show="!open"
            class="open-sidebar-icon"
          />
          <CloseIcon
            v-show="open"
            class="open-sidebar-icon"
          />
        </div>
        <div class="logo">
          <Logo class="logo-icon" />
        </div>
        <Breadcrumb v-if="device === 'desktop'" />
      </div>
      <div class="profile">
        <ExtraItem
          v-if="device === 'mobile'"
          id="extra"
          :items="navItems"
          :name="$t('Menu.Items.Navigation')"
          :title="$t('Menu.Items.Navigation')"
          :border="false"
          :variant="variant"
          :hidden-tooltip="true"
          tooltip-placement="bottom"
        />

        <UnitSelector
          v-show="device !== 'mobile' && !operatorMode && hasSites()"
          :device="device"
          variant="short"
        />

        <div
          v-if="device !== 'mobile'"
          class="nav-items"
        >
          <NavItem
            item-id="myind-navbar"
            :name="$t('Menu.Items.MyInd')"
            variant="short"
            :icon="icons.MyIndIcon"
            tooltip-placement="bottom"
            @click="() => redirect('myind')"
          />

          <NavItem
            v-if="pcpAccess && operatorMode"
            :name="$t('PCP Mode')"
            variant="short"
            :icon="icons.PCPMode"
            tooltip-placement="bottom"
            @click="() => toggleOperator()"
          />

          <NavItem
            v-if="operatorMode"
            :name="$t('Operator.Navigation.Title')"
            variant="short"
            :icon="icons.OperatorNavigationMenuIcon"
            tooltip-placement="bottom"
            @click="() => toggleNavigationMenu()"
          />
          <NavItem
            v-if="!operatorMode"
            :name="$t('Menu.Items.Support')"
            variant="short"
            stroke-hover
            :selected="currentPage.includes('/support')"
            :icon="icons.HeadsetIcon"
            data-redirect="support"
            tooltip-placement="bottom"
            :hidden-tooltip="device !== 'desktop'"
            @click="() => redirect('support')"
          />
        </div>
        <OperatorNavigationMenu
          :open="isMenuOpen"
          @close="toggleNavigationMenu"
        />
        <div
          v-show="operatorMode"
          id="profile_trigger_navbar_operator"
          class="profile-operator"
          tabindex="0"
        >
          <div class="user">
            <h3>{{ user.first_name }} {{ user.last_name }}</h3>
            <h2>{{ user.job_role }}</h2>
          </div>
          <div class="picture">
            <img
              v-if="user.photo"
              :src="user.photo"
              alt="User photo"
            >
            <AvatarPlaceholder
              v-else
              size="38px"
              :text="picturePlaceholder"
            />
          </div>
          <div class="arrow-down">
            <ArrowDown class="arrow-down-icon" />
          </div>
        </div>
        <div
          v-show="!operatorMode"
          id="profile_trigger_navbar"
          tabindex="0"
          class="picture"
        >
          <img
            v-if="user.photo"
            :src="user.photo"
            alt="User photo"
          >
          <AvatarPlaceholder
            v-else
            size="30px"
            :text="picturePlaceholder"
          />
        </div>
        <CollapsePopover
          :items="operatorItems"
          target="profile_trigger_navbar_operator"
          placement="down"
          custom-position="navbar"
        />
      </div>
      <Popover
        :items="items"
        target="profile_trigger_navbar"
        :title="$t('Menu.Titles.Account')"
        :second-title="$t('Menu.Languages.Title')"
        :user="user"
        is-profile-popover
        has-language
        placement="bottom"
        custom-position="navbar"
        triggers="focus"
      />
      <Menu
        v-show="open"
        :user="user"
        :device="device"
        :close-dropdown="open"
        wide
        @close="closeFullscreenMenu"
        @logout="logout"
        @toggleOperator="toggleOperator"
      />
    </div>
    <div id="navbar-breadcrumbs">
      <Breadcrumb
        v-if="device !== 'desktop'"
        :device="device"
      />
    </div>
  </div>
</template>

<script>
import Logo from '@core/assets/mysfc-logo.svg'
import MyIndIcon from '@core/assets/icons/myind.svg'
import HamburgerIcon from '@core/assets/icons/hamburger-icon.svg'
import CloseIcon from '@core/assets/icons/close-icon.svg'
import HeadsetIcon from '@core/assets/icons/headset-icon.svg'
import ArrowDown from '@core/assets/icons/arrow-down.svg'
import PCPMode from '@core/assets/icons/pcpmode.svg'
import OperatorNavigationMenuIcon from '@core/assets/icons/applications.svg'
import ProfileIcon from '@core/assets/icons/new-profile-icon.svg'
import BrazilFlag from '@core/assets/icons/brazil-flag.svg'
import USAFlag from '@core/assets/icons/usa-flag.svg'
import ExitIcon from '@core/assets/icons/new-exit-icon.svg'
import LanguageIcon from '@core/assets/icons/language.svg'
import SwtichAccountIcon from '@core/assets/icons/switchaccount.svg'

import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue'
import Popover from '../Popover.vue'
import NavItem from '../NavItem.vue'
import NavItemGroup from '../NavItemGroup.vue'
import Tooltip from '../Tooltip.vue'
import UnitSelector from '../UnitSelector'
import Breadcrumb from '../../../../../../layouts/components/Breadcrumb.vue'
import OperatorNavigationMenu from '../OperatorNavigationMenu/index.vue'
import CollapsePopover from '../CollapsePopover.vue'

import ExtraItem from '../Extra'
import Menu from '../Sidebar/index.vue'

const ICONS = {
  ProfileIcon,
  ExitIcon,
  MyIndIcon,
  HeadsetIcon,
  CloseIcon,
  LanguageIcon,
  SwtichAccountIcon,
  OperatorNavigationMenuIcon,
  PCPMode,
  BrazilFlag,
  USAFlag,
}

export default {
  name: 'NavbarApp',
  components: {
    Logo,
    MyIndIcon,
    HamburgerIcon,
    CloseIcon,
    HeadsetIcon,
    ArrowDown,
    BrazilFlag,
    OperatorNavigationMenuIcon,
    PCPMode,
    SwtichAccountIcon,
    LanguageIcon,
    USAFlag,
    ExtraItem,
    OperatorNavigationMenu,
    Menu,
    Tooltip,
    Popover,
    AvatarPlaceholder,
    NavItem,
    NavItemGroup,
    Breadcrumb,
    CollapsePopover,
    UnitSelector,
  },
  props: {
    user: {
      type: Object,
    },
    device: {
      type: String,
      default: 'desktop',
    },
    operatorMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const self = this
    return {
      open: false,
      icons: ICONS,
      isMenuOpen: false,
      pcpAccess: true,
      navItems: [
        {
          title: 'Menu.Items.MyInd',
          icon: ICONS.MyIndIcon,
          click: () => (window.location.href = this.$myIndLink),
        },
        {
          title: 'Menu.Items.Support',
          icon: ICONS.HeadsetIcon,
          click: () => (window.location.href = `${this.$myIndLink}${localStorage.getItem(
            'prefix',
          )}/support`),
        },
      ],
      operatorItems: [
        {
          title: 'Menu.Items.Account',
          icon: ICONS.ProfileIcon,
          click: () => (window.location.href = `${this.$myIndLink}${localStorage.getItem(
            'prefix',
          )}/account`),
        },
        {
          title: 'ChangeAccount',
          icon: ICONS.SwtichAccountIcon,
          click: () => self.redirect('change-account'),
        },
        {
          title: 'Support',
          icon: ICONS.HeadsetIcon,
          click: () => (window.location.href = `${this.$myIndLink}${localStorage.getItem(
            'prefix',
          )}/support`),
        },
        {
          title: 'Menu.Languages.Title',
          icon: ICONS.LanguageIcon,
          items: [
            {
              title: 'Menu.Languages.English',
              icon: ICONS.USAFlag,
              click: () => this.changeLanguage('en-US'),
            },
            {
              title: 'Menu.Languages.Portuguese',
              icon: ICONS.BrazilFlag,
              click: () => this.changeLanguage('pt-BR'),
            },
          ],
        },
        {
          title: 'Menu.Items.Exit',
          icon: ICONS.ExitIcon,
          click: () => self.logout(),
        },
      ],
      items: [
        {
          title: 'Menu.Items.Account',
          icon: ICONS.ProfileIcon,
          click: () => (window.location.href = `${this.$myIndLink}${localStorage.getItem(
            'prefix',
          )}/account`),
        },
        {
          title: 'Menu.Items.Exit',
          icon: ICONS.ExitIcon,
          click: () => self.logout(),
        },
      ],
    }
  },
  computed: {
    picturePlaceholder() {
      const firstLetter = str => String(str)?.[0]?.toUpperCase() ?? ''
      return `${firstLetter(this.user.first_name)}${firstLetter(
        this.user.last_name,
      )}`
    },
    currentPage() {
      return this.$route.path
    },
  },
  created() {
    this.verifyPCPAccess()
  },
  methods: {
    changeLanguage(slug) {
      this.$i18n.locale = slug
      localStorage.setItem('language', slug)
    },
    verifyPCPAccess() {
      const userPCPAccess = this.$cookies.get('userInfo').userData.pcp_access
      userPCPAccess === '0'
        ? (this.pcpAccess = false)
        : (this.pcpAccess = true)
    },
    logout() {
      this.$emit('logout')
    },
    toggleFullScreenMenu() {
      this.open = !this.open
    },
    toggleNavigationMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    hasSites() {
      const hasCookies = this.$cookies.get('userInfo').userData.sites;
      return hasCookies.length > 0 ? true : false;
    },
    toggleOperator() {
      this.isMenuOpen = false
      this.$emit('toggleOperator')
    },
    closeFullscreenMenu() {
      this.open = false
    },
    redirect(page) {
      if (this.$route.name === page) return
      if (page == 'myind') {
        window.location.href = this.$myIndLink
        return
      }

      if (page === 'support') {
        window.location.href = `${this.$myIndLink}${localStorage.getItem(
          'prefix',
        )}/support`
        return
      }

      const tenant = this.user.email.split('@')
      this.prefix = this.prefix ? this.prefix : tenant[1].split('.')[0]

      this.$router.push({ name: page, params: { prefix: this.prefix } })

      if (this.wide) {
        this.$emit('close')
      }
    },
    addVisibleNavItems() {
      const { sidebar } = this.$refs
      const navItemGroups = [this.$refs.generalGroup, this.$refs.sites]

      // Tamanho da viewport
      const viewportHeight = window.innerHeight - 100
      // Posição do topo do sidebar em relação ao documento
      const sidebarTop = sidebar.getBoundingClientRect().top
      // Itens visíveis e itens que ultrapassam o limite da viewport
      const visibleNavItems = []
      const overflowNavItems = []

      for (const group of navItemGroups) {
        const groupTitle = group.title
        const navItems = group.$children
        for (const item of navItems) {
          const itemRect = item.$el.getBoundingClientRect()
          const itemTop = itemRect.top - sidebarTop
          if (
            itemTop >= 0
            && itemTop + itemRect.height + 30 <= viewportHeight
          ) {
            // O item está visível na viewport
            visibleNavItems.push({
              group: groupTitle,
              navItem: item,
            })
          } else {
            // O item ultrapassa o limite da viewport
            overflowNavItems.push({
              group: groupTitle,
              name: item.name,
              icon: item.icon,
              click: () => this.redirect(item.$el.dataset.redirect),
            })
          }
        }
      }

      // Atualizar a lista de itens visíveis e itens que ultrapassam a viewport
      this.visibleNavItems = visibleNavItems
      this.overflowNavItems = overflowNavItems
    },
  },
}
</script>

<style lang="scss" scoped>
#navbar {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);
  align-items: center;

  &.remove-shadow {
    box-shadow: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    z-index: -1;
  }
}

#navbar-breadcrumbs {
  padding: 0 2px;
  border-top: 1px solid #eee;
  background-color: #fff;
}

.nav-items {
  display: flex;
  align-items: center;
}

.side {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  .toggle {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-right: 1px solid #eee;
    svg {
      fill: #998f8a;
    }
    &:hover {
      background-color: #ffede1 !important;
      svg {
        fill: #974900 !important;
      }
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 100%;
    padding-left: 16px;
  }
}

.profile {
  cursor: pointer;
  border-left: 1px solid #eee;
  height: 100%;
  padding: 0 7px 0 0;
  display: flex;
  flex-direction: row;

  .my-ind,
  .support {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    .myind-icon,
    .support-icon {
      width: 16px;
      height: 16px;
      svg {
        fill: #4c4541;
      }
    }
  }
  .user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: flex-end;
    width: 93px;

    h3,
    h2 {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    h3 {
      color: #4c4541;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h2 {
      color: #998f8a;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .profile-operator {
    cursor: pointer;
    border-left: 1px solid #eee;
    height: 100%;
    padding-left: 12px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    max-width: 243px;
    min-width: 0;

    .user {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: flex-start;
      max-width: 100%;
      min-width: 0px;

      h3,
      h2 {
        margin: 0;
        padding: 0;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
      }

      h3 {
        color: #4c4541;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      h2 {
        color: #998f8a;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: right;
        width: -webkit-fill-available;
      }
    }

    .picture {
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        background-color: unset !important;
      }
    }

    img {
      width: 38px;
      height: 38px;
      object-fit: cover;
      border-radius: 100%;
    }

    .arrow-down {
      height: 100%;
      width: 16px;
      display: flex;
      align-items: center;
      svg {
        fill: #998f8a;
      }
    }
  }

  .picture {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 9px;

    &:hover {
      background-color: #ffede1 !important;
    }
  }

  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100%;
  }

  .arrow-down {
    height: 100%;
    width: 16px;
    display: flex;
    align-items: center;
    svg {
      fill: #998f8a;
    }
  }
}

.sidebar-open-button {
  padding: 16px;
  .open-sidebar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }
}

.logo-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-down-icon {
  width: 16px;
  height: 16px;
}

@media (max-width: 480px) {
  #navbar {
    .side {
      .logo {
        padding-left: 12px;
      }
    }
  }
}
</style>
