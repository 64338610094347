<template>
  <div class="sites-section">
    <h3>Sites</h3>
    <ul>
      <li
        v-for="site of sites"
        :key="site.guid"
        :class="[currentSite && currentSite.guid === site.guid ? 'selected' : '']"
        @click="setSite(site)"
      >
        {{ site.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sites: [],
      currentSite: null,
    }
  },
  beforeMount() {
    this.getSites()
  },
  methods: {
    getSites() {
      this.sites = JSON.parse(localStorage.getItem('sites'))
      this.currentSite = localStorage.getItem('currentSite')
    },
    setSite(site) {
      localStorage.setItem('currentSite', JSON.stringify(site));
      const domain = window.location.href.includes("indsiders.com");
      const userInfo = this.$cookies.get('userInfo');

      this.$cookies.set(
        "userInfo",
        {...userInfo, currentSite: site },
        null,
        null,
        domain ? "indsiders.com" : null
      );

      this.$router.go()
    },
  },
}
</script>

<style lang="scss" scoped>
.sites-section {
  width: 18rem;
  height: inherit;
  border-left: 1px solid #cfc4be;
  padding: 16px;
  max-width: 246px;

  h3,
  li {
    font-family: "Raleway";
    font-style: normal;
    font-size: 16px;
 }

  h3 {
    font-weight: 600;
    line-height: 26px;
    color: #4c4541;
  }
  li {
    padding: 8px 16px;
    list-style: none;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 6px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: #ffdbc4;
      color: #974900;
    }
    &:active,
    &.selected {
      background: #974900;
      color: white;
    }
  }
  ul {
    padding: 0;
  }
}
</style>
